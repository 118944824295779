import { useState, useEffect } from 'react';
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Stack,
  Pagination,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from '@mui/material';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircularProgress from '@mui/material/CircularProgress';

import axiosInstance from '../config/AxiosInstance';

import Page from '../admin/components/Page';

export default function ReportedWebsite() {
  const [ReportedWebsite, setReportedWebsite] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    async function fetchReportedWebsite() {
      try {
        const decodedToken = localStorage.getItem('decodedToken');

        if (decodedToken) {
          const parsedToken = JSON.parse(decodedToken);
          const userId = parsedToken.userId?.user_id;

          const response = await axiosInstance.get(`/reportedwebsite/reported/${userId}`, {
            params: { page, itemsPerPage },
          });

          if (response.status === 200) {
            const startIndex = (page - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            const paginatedWebsites = response.data.data.slice(startIndex, endIndex);

            setReportedWebsite(paginatedWebsites);
            setTotalPages(Math.ceil(response.data.data.length / itemsPerPage));
          } else {
            throw new Error('Failed to fetch reported websites');
          }
        } else {
          throw new Error('User ID not found in decoded token');
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }

    fetchReportedWebsite();
  }, [page, itemsPerPage]);

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setPage(1);
  };

  return (
    <Page title="Reported Website" style={{ paddingLeft: '10px', paddingRight: '10px' }} sx={{ mt: 3, pt: 10 }}>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          <Typography variant="h4" gutterBottom sx={{ paddingBottom: '15px' }}>
            My Reported Website
          </Typography>
          {ReportedWebsite.length > 0 ? (
            ReportedWebsite.map((website) => (
              <Card key={website._id} sx={{ marginBottom: '15px' }}>
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '30px',
                  }}
                >
                  <Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography style={{ paddingBottom: '10px' }}>
                        <FontAwesomeIcon
                          icon={faDotCircle}
                          style={{
                            color: website.reported ? 'red' : 'gray',
                            fontSize: '0.9em',
                            marginRight: '5px',
                          }}
                        />
                        <span style={{ color: website.reported ? 'red' : 'gray' }}>
                          {website.reported ? 'Reported' : 'Pending'}
                        </span>
                      </Typography>
                      <Typography style={{ fontSize: '1.2em' }}>
                        <span style={{ fontWeight: 'bold' }}>URL: </span>
                        {website.url}
                      </Typography>
                      <Typography style={{ color: '#0E86D4', paddingTop: '10px' }}>
                        Cost: ${website.charges || 0}
                      </Typography>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginTop: '10px' }}>
                      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                        <Typography style={{ marginBottom: '10px' }}>
                          <span style={{ fontWeight: 'bold', Bottom: '50px' }}>Organic Visits : </span>
                          {website.monthlyVisits}
                        </Typography>
                        <Typography style={{ marginBottom: '10px' }}>
                          <span style={{ fontWeight: 'bold' }}>Domain Authority : </span>
                          {website.DA}
                        </Typography>
                        <Typography style={{ marginBottom: '10px' }}>
                          <span style={{ fontWeight: 'bold' }}>Spam Score : </span>
                          {website.spamScore}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                        <Typography style={{ marginBottom: '10px' }}>
                          <span style={{ fontWeight: 'bold' }}>Link Period : </span>
                          {website.linkTime}
                        </Typography>
                        <Typography style={{ marginBottom: '10px' }}>
                          <span style={{ fontWeight: 'bold' }}>Link Type : </span>
                          {website.linkType}
                        </Typography>
                        <Typography style={{ marginBottom: '10px' }}>
                          <span style={{ fontWeight: 'bold' }}>Link Quantity : </span>
                          {website.backlinksAllowed}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                        <Typography style={{ marginBottom: '10px' }}>
                          <span style={{ fontWeight: 'bold' }}>Country : </span>
                          {website.country}
                        </Typography>
                        <Typography style={{ marginBottom: '10px' }}>
                          <span style={{ fontWeight: 'bold' }}>Language : </span>
                          {website.language}
                        </Typography>
                        <Typography style={{ marginBottom: '10px' }}>
                          <span style={{ fontWeight: 'bold' }}>Google News : </span>
                          {website.surfaceInGoogleNews ? 'Yes' : 'No'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                        <div style={{ margin: '15px' }}>
                          <Button variant="contained" color="primary" sx={{ marginRight: '10px' }}>
                            Contact
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography>No Reported Websites</Typography>
          )}
        </>
      )}
      <hr style={{ borderTop: '1px solid black', width: '100%', margin: '20px 0' }} />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <>
          <FormControl margin="normal" sx={{ '& .MuiInput-root': { paddingTop: '18px' } }}>
            <InputLabel sx={{ backgroundColor: 'white', paddingRight: '5px', paddingLeft: '5px' }}>Page</InputLabel>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              label="Items per Page"
              sx={{ fontSize: '0.9rem' }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
            </Select>
          </FormControl>
        </>

        {totalPages > 1 && (
          <Stack spacing={2} sx={{ justifyContent: 'center' }}>
            <Pagination count={totalPages} page={page} onChange={(event, value) => setPage(value)} color="primary" />
          </Stack>
        )}
      </div>
    </Page>
  );
}
